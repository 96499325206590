<template>
  <div class="table-responsive adaptive-table" v-if="list.length > 0">
    <table class="table table-striped table-hover adaptive-table" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in dataList" :key="row.id" @click="prepareInfo(row)">
          <td :data-label="$t('sendFromDate')">{{ humanDateTime(row.send_from_date, $i18n.locale) }}</td>
          <td :data-label="$t('status')">{{ $t(`scheduledCampaignStatus.${row.status}`) }}
            <complex-confirmation
              v-if="row.cancelable"
              :handle-confirmation="cancelHandler.bind(null, row.id, license)"
              :content="$t('cancelSendingConfirmation')" v-slot="{ click }">&nbsp;
              <button class="btn btn-danger btn-mc" @click.stop="click">{{$t('cancelSending')}}</button>
            </complex-confirmation>
          </td>
          <td :data-label="$t('recipients')">{{ row.recipients }}</td>
          <td :data-label="$t('sandbox')">{{ row.sandbox ? $t('yes') : $t('no') }}</td>
          <td :data-label="$t('sendFromTime')">{{ row.send_from_time }} - {{ row.send_to_time }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useHumanDate } from '@/application/composables/humanDate.js'
import { useTableHandlers } from '@/application/composables/messagesTable.js'
import { cancelBulk } from '@/application/services/statistic'
import { ref, defineAsyncComponent } from 'vue'

export default {
  name: 'ScheduledCampaignsTable',
  components: {
    'complex-confirmation': defineAsyncComponent(() => import('@/application/components/modals/ComplexConfirmation')),
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { humanDateTime } = useHumanDate()
    const i18n = useI18n()
    const { openModal } = useTableHandlers()
    const dataList = ref(props.list)
    const fields = ['sendFromDate', 'status', 'recipients', 'sandbox', 'sendFromTime']
    const prepareInfo = (info) => {
      const obj = {
        sendFromDate: humanDateTime(info.send_from_date, i18n.locale),
        status: i18n.t(`scheduledCampaignStatus.${info.status}`),
        recipients: info.recipients,
        subscribedRecipients: info.subscribed_recipients,
        unsubscribedRecipients: info.unsubscribed_recipients,
        complainedRecipients: info.complained_recipients,
        sandbox: info.sandbox ? i18n.t('yes') : i18n.t('no'),
        date: humanDateTime(info.create_date, i18n.locale),
        sendFromTime: `${info.send_from_time} - ${info.send_to_time}`,
      }
      openModal(obj)
    }
    return {
      humanDateTime,
      prepareInfo,
      fields,
      dataList,
      cancelHandler: (id, license) => {
        return cancelBulk(id, { Authorization: license.license_key, 'Loop-Secret-Key': license.secret_api_key })
          .then(({ data }) => {
            dataList.value = dataList.value.map((row) => row.id === id ? data : row)
          })
      },
    }
  },
}
</script>
